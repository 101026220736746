import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import HomeView from '../views/HomeView.vue'
import TermsView from '../views/terms.vue'
import ShopView from '../views/ShopView.vue'
import PaymentChannels from '../views/PaymentChannels.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView
  },
  {
    path: '/paymentchannels',
    name: 'paymentchannels',
    component: PaymentChannels
  },
  {
    path: '/ps-confirmation/:reference',
    props: true,
    name: 'confirmation',
    component: () => {
      return import(/* webpackChunkName: "ps-confirmation" */ '../views/SuccessView.vue');
    },
  },
  {
    path: '/monnify/:reference/:phone',
    props: true,
    name: 'Monnify',
    component: () => {
      return import(/* webpackChunkName: "monnify" */ '../views/MonnifyView.vue');
    },
  },
  {
    path: '/remita-mandate/:reference',
    props: true,
    name: 'RemittaMandate',
    component: () => {
      return import(/* webpackChunkName: "remita_mandate" */ '../views/RemitaMandateView.vue');
    },
  },
  {
    path: '/remita-otp/:reference/:phone',
    props: true,
    name: 'RemittaOtp',
    component: () => {
      return import(/* webpackChunkName: "remita_mandate" */ '../views/RemitaOtp.vue');
    },
  },
  {
    path: '/fw-confirmation',
    props: true,
    name: 'fw-confirmation',
    component: () => {
      return import(/* webpackChunkName: "fw-confirmation" */ '../views/FlwSuccessView.vue');
    },
  },
  {
    path: '/login',
    props: true,
    name: 'Login',
    component: () => {
      return import(/* webpackChunkName: "login" */ '../views/LoginView.vue');
    },
  },
  {
    path: '/paygo',
    props: true,
    name: 'Paygo',
    component: () => {
      return import(/* webpackChunkName: "paygo" */ '../views/PaygoView.vue');
    },
  },
  {
    path: '/register/:account/:code',
    props: true,
    name: 'Register',
    component: () => {
      return import(/* webpackChunkName: "Register" */ '../views/RegisterView.vue');
    },
  },
  {
    path: '/pay/:account/:code',
    props: true,
    name: 'Pay',
    component: () => {
      return import(/* webpackChunkName: "Pay" */ '../views/PayView.vue');
    },
  },
  {
    path: '/pay/:account',
    props: true,
    name: 'PayNoAuth',
    component: () => {
      return import(/* webpackChunkName: "PayNoAuth" */ '../views/PayView.vue');
    },
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
