<template>
  <div>
    <v-container>
      <v-row class="mb-1">
        <v-col>
          <v-card class="mx-auto" elevation="20">
            <v-row>
              <v-col cols="12">
                <v-card-title>DIRECT DEBIT MANDATE</v-card-title>
                <v-card-text>
                  By checking this box, customer authorizes Solar Energy by
                  d.light Limited to charge customer’s payment method for each
                  credit fee payment weekly or monthly until the total purchase
                  price is completed. The weekly or monthly amounts, tenor and
                  total purchase price are based on Customers Payment plan as
                  stated in SECTION 1 of the Customer Agreement and the payment
                  will be debited as stated on the Customer Agreement.
                </v-card-text>

                <v-card-subtitle>DIRECT DEBIT TERMS</v-card-subtitle>
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <p>
                        1. By authorizing recurring payments, Customer
                        authorizes Solar Energy by d.light Limited to process
                        such payments as electronic debits from the designated
                        bank account (in the case of Automated Clearing House or
                        similar debits), or as charges to the designated card
                        account (in the case of credit card or similar payments)
                      </p>
                      <p>
                        2. Customer agrees that this Direct debit instruction
                        will be issued and delivered to entities commissioned
                        and authorized by Solar Energy by d.light Limited and
                        shall be treated as though they have been signed by the
                        customer.
                      </p>
                      <p>
                        3. If any payment is returned unpaid or if any credit
                        card or similar transaction is rejected or denied, Solar
                        Energy by d.light Limited or its service providers
                        reserve the right to collect any applicable product,
                        insufficient funds fee to the maximum extent permitted
                        by applicable law and to process any such fees as an
                        Electronic Payment or to invoice Customer for the amount
                        due.
                      </p>
                      <p>
                        4. Customer agrees to pay any bank charges relating to
                        this mandate.
                      </p>
                      <p>
                        5. This Mandate may be cancelled by customer after the
                        total purchase price is completed or after receiving
                        approval from Solar Energy by d.light Limited following
                        a ten (10) Business Days’ notice in writing, sent by
                        prepaid registered post, or delivered to the d.light
                        address stated above.
                      </p>
                      <p>
                        6. Customer understands that there will be no refund of
                        amounts which may have already been withdrawn while this
                        mandate was in force if such amounts were legally owing
                        to Solar Energy by d.light Limited.
                      </p>
                      <p>
                        7. Solar Energy by d.light Limited shall not be liable
                        for any error made in the payment of your Direct Debit.
                      </p>
                      <p>
                        8. Solar Energy by d.light Limited shall not be liable
                        for any mandate fraud in the course of the payment of
                        your Direct Debit.
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                                <v-card-subtitle>COUNTER INDEMNITY</v-card-subtitle>
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <p>
                        1.	IN CONSIDERATION of you, Solar Energy by d.light Limited (hereinafter called “D.LIGHT”), instructing from time to time my bank to debit our/my account pursuant to the Direct Debit Mandate executed by us/me and in accordance with the Direct Debit Agreement stated on the Customer Agreement between my bank and D.LIGHT, we/I hereby warrant and undertake to indemnify you upon your first demand in writing against all actions, losses, damages, claims, demands costs and expenses (including legal costs, attorney fees and expenses on a full indemnity basis) howsoever arising, which you may incur or sustain directly or indirectly from or in connection with any instructions given by D.LIGHT to my bank pursuant to our/my Direct Debit Mandate.
                      </p>
                      <p>
                        2.	We/I authorise you to instruct my bank to originate or initiate any Direct Order/Debit Transaction on our/my behalf pursuant to the Direct Debit Mandate issued by us/me without further reference to or authority from us/me. Furthermore, with respect to any claims or demand for the refund of any money received by you pursuant to the Direct Debit Mandate issued by us/me, you are hereby authorised and are at liberty to comply with such demands and claims and without any further reference to or authorisation from us/me, you may originate or initiate a Direct Order to my bank to transfer such funds to your account.
                      </p>
                      <p>
                       3.	This Counter Indemnity is to be in addition to and is not to prejudice or be prejudiced by any other indemnity which has been or may now or hereafter be executed by us/me in connection with any agreement between us, and shall be binding on us/me as continuing security notwithstanding any payments from time to time made to you or any settlement of account or disability, incapacity, insolvency or bankruptcy that may affect us/me or any other thing whatsoever.
                      </p>
                      <p>
                      4.	You are to be at liberty without thereby affecting your rights hereunder at any time and from time to time at your absolute discretion to release, discharge, compound with or otherwise vary or agree the liability under this Counter Indemnity or make any other arrangements with us/me.
                      </p>
                      <p>
                        5.	This Counter Indemnity shall be enforceable notwithstanding any change in your name or any change in your constitution, your successors or assigns or by your amalgamation with any other company.
                      </p>
                      <p>
                       6.	We/I confirm that we/I have the authority and legal capacity to issue this Counter Indemnity in favour and for the benefit of d.light.
                      </p>
                      <p>
                        7.	This Counter Indemnity shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.
                      </p>

                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>



<style scoped>
@import "../assets/mystyles.css";
</style>

<script>
// Components

export default {
  name: "TermsView",

  components: {},
  data() {
    return {
      colors: [
        "green lighten-4",
        "secondary lighten-4",
        "yellow lighten-4",
        "red lighten-4",
        "orange lighten-4",
      ],
      cycle: false,
      slides: [
        {
          src: require("../assets/img/headerimage.jpg"),
        },
      ],
    };
  },
};
</script>
