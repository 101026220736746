import Vue from 'vue'
import Vuex from 'vuex'
import api from './modules/api';
import { format, formatRelative, formatDistance } from 'date-fns'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mainTitle: { title: "Welcome", icon: "mdi-fire" },
    loading: false,
    user: {},
    token: null,
    isAuthenticated: false,
    snackbar: false,
    snackBarText: "",
    snackBarColor: "purple",
    account: {},
    currency: {
      symbol: '₦',
      name: "naira"
    },
    country: {},
  },
  getters: {
    getUserId: (state) => {
      return state.user._id;
    },
    getCurrentUser: (state) => {
      return state.user;
    },
    formatDateRelative: () => (date) => {
      return formatRelative(date, new Date());
    },
    formatDateDistance: () => (date) => {
      return formatDistance(date, new Date());
    },
    formatDateMed: () => (date) => {
      return date ? format(new Date(date), "MMM dd, yyyy") : ' - ';
    },
    formatTime: () => (date) => {
      return date ? format(new Date(date), "h:mm:ss b") : ' - ';
    },
    formatDateLong: () => (date) => {
      return date ? format(new Date(date), "EEEE, MMM dd, yyyy") : ' - ';
    },
    formatDayName: () => (date) => {
      return date ? format(new Date(date), "EEEE") : ' - ';
    },
    getCurrentUsername: (state) => {
      return state.user.name || state.user.username;
    },
    formatCurrencyDecimal: (state) => (n, c, symbol) => {
      c = c ? c : 2;

      let d = '.';
      let t = ',';
      let s = n < 0 ? '-' : '';
      let i = parseInt(n = Math.abs(+n || 0).toFixed(2)) + '';
      let j = i.length
      j = j > 3 ? j % 3 : 0;
      let val = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(
        n - i).toFixed(c).slice(2) : '');
      return symbol ? state.currency.symbol + val : val;
    },
    formatCurrency: (state) => (n, c, symbol) => {
      c = c ? c : 2;
      let d = d === undefined ? '.' : d;
      let t = t === undefined ? ',' : t;
      let s = n < 0 ? '-' : '';
      let i = parseInt(n = Math.abs(+n || 0).toFixed(2)) + '';
      let j = (j = i.length) > 3 ? j % 3 : 0;
      let val = s + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t);
      return symbol ? state.currency.symbol + val : val;
    },
  },
  mutations: {
    setTitle(state, payload) {
      state.mainTitle = payload;
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setSnackBarText(state, payload) {
      state.snackBarText = payload;
    },
    setSnackBarColor(state, payload) {
      state.snackBarColor = payload;
    },
    setBusy(state, val) {
      state.loading = val;
    },
  },
  actions: {
    showMessage: ({ commit, dispatch }, options) => {
      if (options.text === "Unauthorized") {
        dispatch('logout');
      }
      commit('setSnackBarText', options.text);
      commit('setSnackBarColor', options.error ? 'deep-orange' : 'info');
      commit('setSnackbar', true);
    },
  },
  modules: {
    api: api,
  }
})
