
<template>
  <div>
    <v-container>
      <v-row class="mb-1">
        <v-col>
          <v-img class="white--text align-end" src="/img/imax.png"> </v-img>
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-col align="center" justify="center">
          <v-btn

          class="font-weight-bold text-subtitle-1 white--text btn"
          rounded
          color="deep-orange lighten-1"
          to="login"
          dark>
            Buy now
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>







<style scoped>
@import "../assets/mystyles.css";
</style>

<script>
// Components

export default {
  name: "ShopView",

  components: {},
  data() {
    return {
      colors: [
        "green lighten-4",
        "secondary lighten-4",
        "yellow lighten-4",
        "red lighten-4",
        "orange lighten-4",
      ],
      cycle: false,
      slides: [
        {
          src: require("../assets/img/headerimage.jpg"),
        },
      ],
    };
  },
};
</script>