<template>
  <div>
    <v-container>
<v-card class="mb-10" flat>
  <v-img
    class="white--text align-end"
    height="356px"
    src="/img/banner.png"
  >
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" class="text-center">
        <v-card-text class="display-1 font-weight-bold white--text text-background">
          Payment options
        </v-card-text>
      </v-col>
    </v-row>
  </v-img>
</v-card>



      <v-row class="mt-5">
        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Auto Debit</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/autodebit.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
           <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >First Bank App</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/firstbankapp.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
           <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >First Bank USSD</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/firstbankussd.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>
      </v-row>
       <v-row class="mt-1">
        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >GTB App</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/gtbapp.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
           <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >GTB USSD</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/gtbussd.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
           <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Paga App</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/pagaapp.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>
      </v-row>
       <v-row class="mt-1">
        <v-col cols="12" sm="4">
          <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Paga USSD</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/pagaussd.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
           <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Paga Wallet</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/pagawallet.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
           <v-card class="justify-center mx-auto" max-width="350" shaped>
            <v-system-bar
              class="justify-center"
              height="50"
              color="deep-orange darken-1"
              dark
            >
              <v-card-title class="font-weight-bold white--text"
                >Paga Web</v-card-title
              >
            </v-system-bar>

            <v-img src="/img/pagaweb.jpg" height="350px" cover></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>



<style scoped>
@import "../assets/mystyles.css";
.text-background {
  background-color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  padding: 10px;
}
</style>

<script>
// Components

export default {
  name: "PaymentChannels",

  components: {},
  data() {
    return {
      colors: [
        "green lighten-4",
        "secondary lighten-4",
        "yellow lighten-4",
        "red lighten-4",
        "orange lighten-4",
      ],
      cycle: false,
      slides: [
        {
          src: require("../assets/img/headerimage.jpg"),
        },
      ],
    };
  },
};
</script>
