<template>
  <v-app>
    <v-app-bar
      :collapse="!collapseOnScroll"
      :collapse-on-scroll="collapseOnScroll"
      fixed
      color="deep-orange darken-1"
      dark
    >
      <v-app-bar-nav-icon>
           <v-img
            width="110px"
            src="/img/logo.svg"
            class="ml-6"
          >
          </v-img>
      </v-app-bar-nav-icon>

      <v-toolbar-title  class="ml-2 mt-3">Solar Energy by d.light</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-0" mt-15>
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- <v-bottom-navigation color="primary">
      <v-btn>
        <span>Recents</span>

        <v-icon>mdi-history</v-icon>
      </v-btn>

      <v-btn>
        <span>Favorites</span>

        <v-icon>mdi-heart</v-icon>
      </v-btn>

      <v-btn>
        <span>Nearby</span>

        <v-icon>mdi-map-marker</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
    <v-footer dark padless>
      <v-card class="flex deep-orange" flat tile>
        <!-- <v-card-title class="dark">
          <strong class="subheading">Get connected with us</strong>

          <v-spacer></v-spacer>

          <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title> -->

        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} —
          <strong>Solar Energy by d.light</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <!-- <div class="text-center ma-2"> -->
    <v-snackbar
      v-model="snackState"
      :color="snackBarColor"
      class="center"
      :timeout="timeout"
    >
      <div style="width: 100%; text-align: center">{{ snackBarText }}</div>
    </v-snackbar>
    <!-- </div> -->
  </v-app>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
// import toast from "./utils/toast";
export default {
  name: "App",
  unmounted() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    ...mapActions(["showMessage"]),
    routeChange: function () {
      // console.log("----Route change---", v);
    },
    goToRoute: function (path) {
      this.menu = false;
      this.$router.push({ path: path });
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
    },
  },

  computed: {
    ...mapState([
      "snackbar",
      "snackBarText",
      "snackBarColor",
      "isAuthenticated",
      "user",
      "mainTitle",
    ]),
    ...mapGetters(["getCurrentUsername"]),
    snackState: {
      get() {
        return this.snackbar;
      },
      set(val) {
        this.setSnackbar(val);
      },
    },
  },
  created: function () {
    // console.log("uuuuiii----->>", this.$vuetify.theme.themes);
    // this.$vuetify.theme.themes.light.primary = "#0074b6";
    // this.$vuetify.theme.themes.dark.primary = "#f57f17";
    // this.$vuetify.theme.themes.dark.accent = "#f57f17";
    // this.$vuetify.theme.themes.light.primary = "#0074b6";
    this.$vuetify.theme.dark = false;
  },
  data: () => {
    return {
      clipped: false,
      drawer: null,
      fixed: false,
      navItems: [],
      collapseOnScroll: true,
      miniVariant: true,
      right: true,
      title: "Hello",
      isMobile: false,
      showFooter: true,
      menu: false,
      timeout: 3000,
      busy: false,
      prefTab: null,
      icons: ["mdi-facebook", "mdi-twitter", "mdi-instagram"],
    };
  },
};
</script>
